
import React, { useState } from 'react';

import { Camera } from "./camrea";
import axios from 'axios'
import './App.css';
import one from './back.png'
import { saveAsPng, saveAsJpeg } from 'save-html-as-image';
import domtoimage from 'dom-to-image'
import V1 from './frame1.png'
import V2 from './newframe2.png'
import V3 from './frame3.png'
import V4 from './frame4.png'


import Down from './down.png'

import Footer from './footer'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
const CamreaApp = () => {

  const scrollToRef = () => window.scrollTo(0, window.innerHeight)   
  const [showDiv, setShowDiv] = useState(false)
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [cardImage, setCardImage] = useState(false);
  const filter = ["inline-svg", "bordered2", "sketchy", "shubharam", "headshot-3", "bordernew", "colorBorder", "nfff"]
  const [clas, Setfil] = useState("real222")
  const [test2, SetTest] = useState("")
  const [totalwords, setTotal] = useState("")
  const [result, setresult] = useState("")
  const [result2, setresult2] = useState("")
  const [download, setDownload] = useState("")
  const [download2, setDownload2] = useState("")
  const [download3, setDownload3] = useState("")


  const dd = () => {

    const node = document.getElementById('parent');

    saveAsPng(node);

  }
  const dd1 = () => {

    const node = document.getElementById('parent2');

    saveAsPng(node);

  }
  const dd2 = () => {

    const node = document.getElementById('parent3');

    saveAsPng(node);

  }

  const dd3 = () => {

    const node = document.getElementById('parent4');

    saveAsPng(node);

  }
  const printBarcode = () => {

    setTimeout(() => {


      domtoimage.toBlob(document.getElementById('parent'))
        .then(function (blob) {

          setresult2(URL.createObjectURL(blob))

          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            console.log(base64data);
            setDownload(base64data)

          }
        })


      domtoimage.toBlob(document.getElementById('parent2'))
        .then(function (blob) {

          setresult2(URL.createObjectURL(blob))

          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data2 = reader.result;
            console.log("base64data2base64data2", base64data2)
            setDownload2(base64data2)
            // axios.post(`http://localhost:4000/data`, {base64data} )
            // .then(res => {
            //   console.log(2222)
            //   console.log(res);
            //   console.log(res.data);
            // })
          }
        })

      domtoimage.toBlob(document.getElementById('parent3'))
        .then(function (blob) {

          setresult2(URL.createObjectURL(blob))

          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data3 = reader.result;

            setDownload3(base64data3)
            // axios.post(`http://localhost:4000/data`, {base64data} )
            // .then(res => {
            //   console.log(2222)
            //   console.log(res);
            //   console.log(res.data);
            // })
          }
        })

    }, 100);
  }

  const handleCap = (blob) => {

    setCardImage(blob)
    // printBarcode()
  }


  return (
    <div className="text-center  ">

      <div class="row   ">



        <div class="col-md-12 col-lg-12 text-center backimgphoto ">


        <div class=" "  style={{position:'absolute',top:'30%',left:'5%',zIndex:10000}}>
        
        
        
            <img  
            onClick={()=>scrollToRef()}
             src={Down}  class="imgLogo " />
            
             
            <p className="menuname" style={{color:'white'}}>Scroll Down To Download</p>
        
        </div>


        <a  href="https://collabera.virtuallive.in/lobby.html"
         style={{zIndex:1000,cursor:'pointer',background:'red'}} > 
        <div  onClick={()=>console.log(8)} class="" style={{position:'absolute',top:'30%',left:'85%',zIndex:10000}} >
        
       
        
            <img src={one} class="imgLogo "  onClick={()=>console.log(8)}/>
           
             
            <p className="menuname" style={{color:'white'}}>Back To Lobby</p>
          
        </div>
           </a> 

          <div class="card  " style={{ background: 'transparent', border: 0, marginTop: '8%' }}> 
          
            <div class="card-heade2r">
              <div class="row ">
                <div class="col-12">
                  <div class="  rounded">
                    {isCameraOpen && <Camera
                      setShowDiv={setShowDiv}
                      showDiv={showDiv}


                      onCapture={blob => handleCap(blob)}
                      onClear={() => setCardImage(undefined)}
                    />}  </div>


                </div>

                {!isCameraOpen && <div class="col-12 col-md-8 text-center mb-5" style={{ visibility: "hidden" }} >
                  <h1 class="display-2 mb-3">Get in CLICKED today</h1>
                  <p class="lead">Welcome to world of awseomeness with Shubharambh Productions.</p>

                </div>}
              </div>

            </div>
            <div class="card-footer px-0 mx-auto " style={{ border: 0, background: 'transparent' }}>

              <button style={{ margin: "10px" }} type="submit"
                onClick={() => setIsCameraOpen(true)} class="btn btn3 btn-primary">Open Camera </button>
              <button style={{ margin: "10px" }} onClick={() => {
                setIsCameraOpen(false);
                setCardImage(undefined);
                setShowDiv(false)
              }} type="submit" class="btn btn3 btn-primary">Close Camera</button>
            </div>

          </div>

        </div>
      


        <Footer />

        {/* <div class="row "  >
                  <div class="col-md-5 col-lg-5" className="unfill" >
                  <img src={V1} className="" alt=""  /> 
                  </div>
                  <div class="col-md-5 col-lg-5"  className="unfill">
                  <img src={V2} className="" alt="" /> 
                  </div>
                  <div class="col-md-5 col-lg-5"  className="unfill">
                  <img src={V3} className="" alt="" /> 
                  </div>
                  <div class="col-md-5 col-lg-5" className="unfill" >
                  <img src={V3} className="" alt="" /> 
                  </div>

                  </div> */}



        {!showDiv && <div class="row backimgphoto2" >
          <div class="col-md-6 col-lg-6"  >
            <div id="parent" className="paren">
              <img src={V1} className="over-im imgp" alt="" />


            </div>



          </div>

          <div class="col-md-6 col-lg-6" >
            <div id="parent2" className="parent">
              <img src={V2} className="over-im imgp" alt="" />


            </div>



          </div>

          <div class="col-md-6 col-lg-6" >
            <div id="parent3" className="parent">
              <img src={V3} className="over-im imgp" alt="" />


            </div>



          </div>

          <div class="col-md-6 col-lg-6" >
            <div id="parent4" className="parent">
              <img src={V4} className="over-im imgp" alt="" />


            </div>



          </div>







        </div>

        }


        {showDiv && <div class="col-md-12 col-lg-12 backimgphoto2 "  >



          {cardImage && <div class="row">
            <div class="col-md-6 col-lg-6" >
              <div id="parent" className="parent">
                <img src={V1} className="over-img imgp" alt="" onClick={() => dd()} />
                <img className="imgp" src={cardImage && URL.createObjectURL(cardImage)} />
                {/* <button download>DOnwload</button>  AIzaSyBkHhQsmHQsJ6HySOWwMUJRu5F0ZJ-FZyE*/}
              </div>

              <img className="down" onClick={() => dd()} style={{ height: "25px" }} src={Down} />
              <p className="menuname" onClick={() => dd()} style={{ color: 'white' }}>Click To Download</p>
              {/* <MyComponent  data={download} /> */}
            </div>
            <div class="col-md-6 col-lg-6" >

              <div id="parent2" className="parent">
                <img src={V2} className="over-img imgp" alt="" onClick={() => dd1()} />
                <img className="imgp" src={cardImage && URL.createObjectURL(cardImage)} />


              </div>


              <img className="down" onClick={() => dd1()} style={{ height: "25px" }} src={Down} />

              <p className="menuname" onClick={() => dd1()} style={{ color: 'white' }}>Click To Download</p>

            </div>


            <div class="col-md-6 col-lg-6" >

              <div id="parent3" className="parent">
                <img src={V3} className="over-img imgp" alt="" onClick={() => dd2()} />
                <img className="imgp" src={cardImage && URL.createObjectURL(cardImage)} />


              </div>


              <img className="down" onClick={() => dd2()} style={{ height: "25px" }} src={Down} />

              <p className="menuname" onClick={() => dd2()} style={{ color: 'white' }}>Click To Download</p>

            </div>

            <div class="col-md-6 col-lg-6" >

              <div id="parent4" className="parent">
                <img src={V4} className="over-img imgp" alt="" onClick={() => dd3()} />
                <img className="imgp" src={cardImage && URL.createObjectURL(cardImage)} />


              </div>


              <img className="down" onClick={() => dd3()} style={{ height: "25px" }} src={Down} />

              <p className="menuname" onClick={() => dd3()} style={{ color: 'white' }}>Click To Download</p>

            </div>






          </div>
          }

        </div>
        }
      </div>
    </div>



  )
}



class MyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    return (<>

      <button onClick={this.openModal} style={{ marginTop: "5%", padding: '5px' }} type="submit" class="btn btn-primary">/> </button>



    </>
    );
  }
}

export default CamreaApp;